<template>
    <div class="user__text__ratting">
        <div v-if="canOrganizeCandidate" v-for="(mark, index) in rateMap" @mouseover="goForRate(mark,index)" @mouseleave="resetRate()" @click="giveRating(mark)" class="rate-count" :class="{'rated' : mark.rated}">
            <i class="eicon e-star"></i>
        </div>
        <div v-if="!canOrganizeCandidate" v-for="(mark, index) in rateMap" class="rate-count" :class="{'rated' : mark.rated}">
            <i class="eicon e-star"></i>
        </div>

    </div>
</template>
<script>
    import client from "../../app/api/Client";
    import {EventBus} from "../../extra/event-bus";
    import {CANDIDATE_RATING_ADDED} from "../../constants/events";
    import {canOrganizeCandidate} from "../../config/permission";
    export default {
        props : {
            rate : {
                type : Number,
                default : 0
            },
            applicant : Object
        },
        data() {
            return {
                rateMap : [
                    {
                        rate : 1,
                        rated : false,
                    },
                    {
                        rate : 2,
                        rated : false,
                    },
                    {
                        rate : 3,
                        rated : false,
                    },
                    {
                        rate : 4,
                        rated : false,
                    },
                    {
                        rate : 5,
                        rated : false,
                    }
                ],
                rating : 0
            }
        },
        methods : {
            goForRate( mark, index) {
                this.rateMap = this.rateMap.map((r,i) => {
                    if (i <= index) {
                        r.rated = true;
                    } else {
                        r.rated = false;
                    }
                    return r;
                });
            },
            resetRate() {
                this.rateMap = this.rateMap.map((r) => {
                    r.rated = false;
                    if (r.rate <= this.rating) {
                        r.rated = true;
                    }
                    return r;
                });
            },
            async giveRating(mark) {
                try {
                    let {data : {data, message}} = await client().put(`/job/applicant/${this.applicant.id}/rating`,{
                        'rating' : mark.rate
                    });
                    this.rating = data.rating;
                    this.$toast.success(message);
                    EventBus.$emit(CANDIDATE_RATING_ADDED, data);
                }catch (e) {
                }
            }
        },
        computed: {
            canOrganizeCandidate() {
                return canOrganizeCandidate();
            }
        },
        mounted() {
            this.rating = this.rate;
            this.resetRate();
        }
    }
</script>
